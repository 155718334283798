import Styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Flex } from '@components/layout/Grid';

const FooterWrapper = Styled(Flex)`
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.neutrals.gray};
  padding: 20px;
`;

const Text = Styled.p`
font-size: 13px;
font-weight: 300;
line-height: 16px;
opacity: 0.8;
margin: 10px 20px;
`;

const LangaguesList = Styled.ul`
display: flex;
font-weight: 600;
list-style: none;
padding-left: 0;
margin: 0 20px;
`;

const Language = Styled.li`
  & + & {
    margin-left: 20px;
  }
`;

const Anchor = Styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const langs = [
  { text: 'Svenska', key: 'se' },
  { text: 'English', key: 'en' },
  { text: 'Norsk', key: 'no' },
  { text: 'Suomi', key: 'fi' },
];

const RegisterFooter = props => {
  const router = useRouter();
  const pathname = router.pathname;

  return (
    <FooterWrapper {...props} alignItems="center" justifyContent="center">
      <Text>©YOGOBE {new Date().getFullYear()}. All rights reserved.</Text>
      <LangaguesList>
        {langs.map(item => {
          const { lang: _, ...params } = router.query;
          const url = Object.keys(params)
            .reduce(
              (url, param) =>
                url.replace(`[${param}]`, router.query[param] as string),
              pathname
            )
            .replace(`[lang]`, item.key);

          return (
            <Language key={item.key}>
              <Anchor href={url}>{item.text}</Anchor>
            </Language>
          );
        })}
      </LangaguesList>
    </FooterWrapper>
  );
};

export default RegisterFooter;
