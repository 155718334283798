import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import Styled from 'styled-components';
import { Formik } from 'formik';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import iconTooltip from '@components/IconTooltip';
import { Box, Flex } from '@components/layout/Grid';
import SimpleList from '@components/SimpleList';
import FormGroup from '@components/form/FormGroup';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import Spinner from '@components/Spinner';
import Button from '@components/buttons';
import { Striked } from '@components/texts';
import SubscriptionOption from '@components/SubscriptionOption';
import { getSubscriptionAmountToPay } from '@pages/[lang]/subscription/[step]';
import { ErrorMessage } from '@pages/[lang]/welcome/[step]';
import { UserContext } from '@lib/contexts/UserProvider';
import Banner from '@organisms/Banner';
import {
  Error,
  CodeStatusKind,
  FullAvailableSubscriptionPlansQuery,
  PricingSubscriptionFieldsFragment,
} from '@gql/generated';
import { CONTACT_EMAIL } from '@lib/constants';
import AccessCodeInput from '@organisms/programs/AccessCodeInput';
import LanguageContext from '@lib/contexts/languageContext';

const Title = Styled.p`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 21px;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 10px;

`;

const SubTitle = Styled.p`
  font-size: 14px;
`;

const ChecklistBox = Styled(Box)`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.primary['blue-4']};
`;

const Paragraph = Styled(RegisterFormTemplate.Paragraph)``;

const ActionButtons = Styled(Flex)`
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
`;

const Submit = Styled(Button)`
  width: 100%;
  max-width: 180px;

  :disabled {
    background-color: ${({ theme }) => theme.colors.neutrals['black-2']};
  }
`;

const ContinueToDashboard = Styled(Button)`
  width: 100%;
  max-width: 180px;
`;

const IconTooltip = Styled(iconTooltip)`
  display: inline-flex;
  margin-left: 5px;
`;

const StyledSubscriptionOption = Styled(SubscriptionOption)`
  margin-top: 10px;
`;

export function getCycles() {
  return {
    12: t`registration.cycle.12months.label`,
    3: t`registration.cycle.3months.label`,
    1: t`registration.cycle.1month.label`,
  };
}

export const SUBSCRIPTION_OPTIONS = {
  yearly: {
    value: 'yearly',
    title: <Trans id="registration.plan.annually.title" />,
    description: <Trans id="registration.plan.annually.body" />,
    cycle: <Trans id="registration.cycle.12months.label" />,
  },
  quarterly: {
    value: 'quarterly',
    title: <Trans id="registration.plan.quarterly.title" />,
    description: <Trans id="registration.plan.quarterly.body" />,
    cycle: <Trans id="registration.cycle.3months.label" />,
  },
  monthly: {
    value: 'monhtly',
    title: <Trans id="registration.plan.monthly.title" />,
    description: <Trans id="registration.plan.monthly.body" />,
    cycle: <Trans id="registration.cycle.1month.label" />,
  },
};

export function getSubscriptionText(
  subscription: PricingSubscriptionFieldsFragment
) {
  const CYCLES = getCycles();

  let description, title;
  const { currency } = subscription;
  const subscriptionAmount = `${getSubscriptionAmountToPay(
    subscription
  )} ${currency}`;

  const cycle = SUBSCRIPTION_OPTIONS[subscription.subscriptionPlan].cycle;

  if (
    // only assign default to actual basic plans
    // global subscriptions will have same subscriptionName as basic plan
    // thats why we make sure the susbcription doesnt have any promotion
    (SUBSCRIPTION_OPTIONS.hasOwnProperty(subscription.subscriptionName) &&
      !subscription.promotionKind &&
      !subscription.promotionName) ||
    // if no title is set in admin we use basic plan title since its global
    (!subscription.title &&
      subscription.promotionKind === 'global' &&
      SUBSCRIPTION_OPTIONS.hasOwnProperty(subscription.subscriptionName))
  ) {
    title = subscription.title;
    if (!title) {
      title = (
        <>
          {React.cloneElement(
            SUBSCRIPTION_OPTIONS[subscription.subscriptionName].title,
            {
              key: subscription.subscriptionName,
              values: {
                // days is for trial
                days: subscription.days,
              },
            }
          )}{' '}
          - {subscriptionAmount}
        </>
      );
    }
    description =
      subscription.shortDescription ||
      subscription.description ||
      React.cloneElement(
        SUBSCRIPTION_OPTIONS[subscription.subscriptionName].description,
        {
          key: subscription.subscriptionName,
          values: {
            // for others
            amount: subscriptionAmount,
          },
        }
      );
  } else if (subscription.title) {
    title = subscription.title + ` - ${subscriptionAmount}`;
    description = subscription.description || subscription.shortDescription;
  } else {
    title = CYCLES[subscription.months] + ` - ${subscriptionAmount}`;
    description = subscription.description || subscription.shortDescription;
  }

  return { title, description, cycle };
}

type Props = {
  data: FullAvailableSubscriptionPlansQuery['availableSubscriptionPlans'];
  validateCode(code?: string): void;
  loading: boolean;
  nextLoading: boolean;
  selectedPlan: string;
  setSelectedPlan(string): void;
  selectedAccess: string;
  setSelectedAccess(string): void;
  selectedOneTimeCode: string;
  setSelectedOneTimeCode(string): void;
  onNext(): void;
  accessActivationErrors: Omit<Error, 'path'>[];
  oneTimeCodeActivationErrors: Omit<Error, 'path'>[];
  showUnexpectedError?: boolean;
};

const SubscriptionForm = ({
  validateCode,
  loading,
  data,
  selectedPlan,
  selectedAccess,
  setSelectedPlan,
  setSelectedAccess,
  selectedOneTimeCode,
  setSelectedOneTimeCode,
  onNext,
  accessActivationErrors,
  oneTimeCodeActivationErrors,
  nextLoading = false,
  showUnexpectedError = false,
}: Props) => {
  const [currentUser] = useContext(UserContext);
  const lang = useContext(LanguageContext);
  const router = useRouter();

  const goToDashboard = () => {
    router.push(`/${lang}/me/dashboard`);
  };

  if (!currentUser) {
    return null;
  }

  let continueButtonText = <Trans id="registration.plan.btn" />;
  if (selectedAccess || selectedOneTimeCode) {
    continueButtonText = <Trans id="registration.plan.continue_btn" />;
  }

  return (
    <Box maxWidth="500px">
      <Box mt={20}>
        <Title>
          <Trans id="registration.plan.title" />
        </Title>
        {currentUser?.subscription?.inTrialPeriod ? (
          <SubTitle>
            <Trans
              id="registration.plan.or_subscription.desc"
              values={{ days: currentUser?.trial?.periodDays || 14 }}
            />
          </SubTitle>
        ) : null}
      </Box>
      <Formik
        initialValues={{
          subscriptionPlan: selectedPlan,
          codeInput: (data?.code || router?.query?.code || '') as string,
        }}
        onSubmit={() => null}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue,
        }) => {
          const formikErrors = errors as {
            codeInput: string;
            subscriptionPlan: string;
          };
          const formikTouched = touched as {
            codeInput: any;
            subscriptionPlan: any;
          };

          return (
            <form
              onChange={e => {
                // if the value of subscriptionPlan changes we update the state above
                if (e.target['name'] === 'subscriptionPlan') {
                  setSelectedAccess(null);
                  setSelectedOneTimeCode(null);
                  setSelectedPlan(e.target['value']);
                } else if (e.target['name'] === 'fullAccess') {
                  setSelectedPlan(null);
                  setSelectedOneTimeCode(null);
                  setSelectedAccess(e.target['value']);
                } else if (e.target['name'] === 'oneTimeCode') {
                  setSelectedPlan(null);
                  setSelectedAccess(null);
                  setSelectedOneTimeCode(e.target['value']);
                }
                handleChange(e);
              }}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <FormGroup>
                <AccessCodeInput
                  inputLabel={t`registration.plan.code.label`}
                  inputName="codeInput"
                  validatedAccessCode={!!data?.code}
                  value={values['codeInput']}
                  onChange={() => {
                    // adding this just to suspend warning, we listen for change on parent form
                  }}
                  clearAccessToken={() => {
                    // if code is present the user has clicked on clear state if button
                    setFieldValue('codeInput', '');
                    validateCode(null);
                  }}
                  checkAccessCodeValidity={() => {
                    const code = values['codeInput'] as string;
                    if (data?.code !== code) {
                      validateCode(code);
                    }
                  }}
                  loading={loading}
                  validationState={
                    data?.isCodeValid
                      ? CodeStatusKind['Valid']
                      : data?.isCodeValid === false
                      ? CodeStatusKind['Invalid']
                      : null
                  }
                  validationText={
                    data?.isCodeValid === false ? (
                      // if we have an error message
                      data?.validationMessage || (
                        <Trans id="promotions.code_apply.fallback_failure_message" />
                      )
                    ) : // if no error is present
                    data?.isCodeValid === true ? (
                      // code is successful
                      <Trans id="registration.plan.code.success" />
                    ) : (
                      // default text
                      <>
                        <Trans id="registration.plan.code.info_text" />
                        <IconTooltip
                          icon={{
                            name: 'ygb-icon-infob',
                            fontSize: '18px',
                          }}
                        >
                          <Trans id="registration.plan.code.info.tooltip" />
                        </IconTooltip>
                      </>
                    )
                  }
                />
                {/* <Paragraph
                  size={13}
                  lineHeight={16}
                  mb={30}
                  // success color
                  {...(data?.isCodeValid
                    ? {
                        color: '#2F7518',
                        'data-testid': 'registration.valid_code.message',
                      }
                    : {})}
                  // error color
                  {...(data?.isCodeValid === false
                    ? {
                        color: '#e53b4d',
                        'data-testid': 'registration.invalid_code.message',
                      }
                    : {})}
                  customStyle={`
                    display: flex;
                    align-items: center;
                  `}
                ></Paragraph> */}
              </FormGroup>
              <ChecklistBox p={20}>
                <Paragraph size={18}>
                  <Trans id="registration.plan.usp.title" />
                </Paragraph>
                <SimpleList
                  items={[
                    <Trans key={0} id="registration.plan.usp1" />,
                    <Trans key={1} id="registration.plan.usp2" />,
                    <Trans key={2} id="registration.plan.usp3" />,
                  ]}
                />
              </ChecklistBox>
              {!loading || data ? (
                <FormGroup
                  data-testid="SubscriptionStep--SubscriptionsList"
                  errorMsg={
                    formikTouched['subscriptionPlan'] &&
                    formikErrors.subscriptionPlan
                  }
                  i18n
                >
                  {data?.oneTimeCodes?.map(subscription => {
                    return (
                      <StyledSubscriptionOption
                        key={subscription.promotionName}
                        name="oneTimeCode"
                        value={subscription.promotionName}
                        title={
                          subscription.title || (
                            <Trans
                              id="subscriptions.one_time_code.default_title"
                              message="Access via one time access code"
                            />
                          )
                        }
                        desc={subscription.description}
                        selected={
                          subscription.promotionName === selectedOneTimeCode
                        }
                        data-testid={`oneTimeCode--${subscription.promotionName}`}
                        data-selected
                      />
                    );
                  })}
                  {data?.fullAccesses?.map(subscription => {
                    return (
                      <StyledSubscriptionOption
                        key={subscription.promotionName}
                        name="fullAccess"
                        value={subscription.promotionName}
                        title={
                          subscription.title || (
                            <Trans
                              id="subscriptions.full_accesse_code.default_title"
                              message="Access via access code"
                            />
                          )
                        }
                        desc={subscription.description}
                        selected={subscription.promotionName === selectedAccess}
                        data-testid={`fullAccessOption--${subscription.promotionName}`}
                        data-selected
                      />
                    );
                  })}
                  {data?.subscriptions
                    ? [...data?.subscriptions]
                        ?.sort((a, b) => {
                          // we sort the subscriptions
                          // promotions are sorted to the top
                          // global subscription promotions should be shown in the place of their parent subscription
                          if (
                            !['global', 'extra_global'].includes(
                              a.promotionKind
                            ) &&
                            (a.promotionKind || a.promotionName)
                          ) {
                            return -1;
                          } else if (
                            !['global', 'extra_global'].includes(
                              b.promotionKind
                            ) &&
                            (b.promotionKind || b.promotionName)
                          ) {
                            return 1;
                          } else {
                            return a.months - b.months;
                          }
                        })
                        .map(subscription => {
                          const { title, description } = getSubscriptionText(
                            subscription
                          );

                          let oldPrice;
                          if (
                            // if the susbcription has a discount we show the old price crossed
                            subscription.discountedAmount &&
                            subscription.discountedAmount !==
                              subscription.amount
                          ) {
                            oldPrice = (
                              <Striked>
                                {subscription.amount + subscription.currency}
                              </Striked>
                            );
                          }

                          // because global subscriptions have same subscriptionName as basic plans
                          // we check that the subscription is not a global
                          const SubscriptionCode = [
                            'global',
                            'extra_global',
                          ].includes(subscription.promotionKind)
                            ? subscription.promotionName
                            : subscription.subscriptionName;

                          return (
                            <StyledSubscriptionOption
                              key={SubscriptionCode}
                              name="subscriptionPlan"
                              value={SubscriptionCode}
                              title={
                                <>
                                  {title} &nbsp; {oldPrice}
                                </>
                              }
                              desc={description}
                              selected={SubscriptionCode === selectedPlan}
                              data-testid={`SubscriptionOption--${subscription.subscriptionName}`}
                            />
                          );
                        })
                    : null}
                </FormGroup>
              ) : (
                <Flex
                  justifyContent="center"
                  my={30}
                  data-testid="SubscriptionStep--SubscriptionsLoading"
                >
                  <Spinner size={24} spinnerColor="#242424" />
                </Flex>
              )}
              {accessActivationErrors?.length ||
              oneTimeCodeActivationErrors?.length > 0 ? (
                <Box pl={20} data-testid="accessActivationErrors">
                  {(accessActivationErrors || oneTimeCodeActivationErrors).map(
                    error => (
                      <ErrorMessage
                        key={error.message}
                        data-testid="accessActivationErrors--Message"
                      >
                        {error.message}
                      </ErrorMessage>
                    )
                  )}
                </Box>
              ) : null}
              {showUnexpectedError ? (
                <Box my="20">
                  <Banner
                    style={{
                      marginBottom: '30px',
                      color: '#C20015',
                      backgroundColor: '#FFE7E7',
                    }}
                    disableIcon
                  >
                    <Trans
                      id="metadata.code_activation.failed"
                      components={[
                        <a key="0" href={`mailto:${CONTACT_EMAIL}`}></a>,
                      ]}
                    />
                  </Banner>
                </Box>
              ) : null}
              <ActionButtons
                justifyContent={['center', 'start', 'start']}
                mt={40}
              >
                <Submit
                  type="submit"
                  appearance="default-blue"
                  onClick={onNext}
                  data-testid="SubscriptionStep--NextButton"
                  loading={nextLoading}
                  {...(!(selectedPlan || selectedAccess || selectedOneTimeCode)
                    ? { disabled: true }
                    : {})}
                >
                  {continueButtonText}
                </Submit>
                {currentUser?.subscription?.inTrialPeriod === true ? (
                  <ContinueToDashboard
                    onClick={goToDashboard}
                    href={`/${lang}/me/dashboard`}
                    appearance="outline"
                  >
                    <Trans id="registration.continue_to_dashboard.btn" />
                  </ContinueToDashboard>
                ) : null}
              </ActionButtons>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SubscriptionForm;
