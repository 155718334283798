import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import {
  useMyPaymentMethodsQuery,
  PaymentMethod as StoredPaymentMethod,
  ProviderKind,
  MyPaymentMethodsQuery,
} from '@gql/generated';
import LanguageContext from '@lib/contexts/languageContext';
import Spinner from '@components/Spinner';
import Banner from '@organisms/Banner';
import { CONTACT_EMAIL } from '@lib/constants';
import { ChoosePaymentMethodModal } from './ChoosePaymentMethodModal';
import Icon from '@components/Icon';
import { Button } from '@components/ButtonV2';
import { UserContext } from '@lib/contexts/UserProvider';
import useFlags from '@lib/hooks/useFlags';

const BackButton = styled(Button)`
  order: -1;
  font-size: 16px;
  margin-bottom: 10px;

  i {
    margin-right: 10px;
  }
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutrals.gray};
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  cursor: progress;
`;

const AdyenContainer = styled.div`
  .adyen-checkout__payment-method {
    background-color: white;
  }

  .adyen-checkout__payment-method--trustly {
    overflow: hidden;
    .adyen-checkout__payment-method__image__wrapper,
    .adyen-checkout__payment-method__image {
      width: 80px;
      height: auto;
    }
    .adyen-checkout__payment-method__image {
      transform: translateY(-7px);
    }

    .adyen-checkout__payment-method__image__wrapper {
      height: 35px;
    }
  }

  .adyen-checkout__payment-method--card {
    .adyen-checkout__payment-method__image__wrapper {
      width: 50px;
      height: auto;

      .adyen-checkout__payment-method__image {
        width: 100%;
        height: auto;
      }
    }
  }

  .adyen-checkout__payment-method--swish {
    .adyen-checkout__payment-method__image__wrapper {
      width: 70px;
      height: 30px;
      overflow: hidden;

      .adyen-checkout__payment-method__image {
        width: 100%;
        height: auto;
        transform: translateY(-7px);
      }
    }
  }

  .adyen-checkout__payment-method__brands {
    height: auto;

    .adyen-checkout__payment-method__image__wrapper {
      height: 26px;
      width: 40px;

      img {
        width: auto;
        height: auto;
      }
    }
  }

  .adyen-checkout__payment-method.adyen-checkout__payment-method--selected {
    /* background-color: rgba(0, 0, 0, .03); */
    border-width: 2px;
  }

  .adyen-checkout__button {
    background-color: ${({ theme }) => theme.colors.primary['blue-2']};

    :hover {
      background-color: ${({ theme }) => theme.colors.primary['blue-1']};
    }
  }
`;

const PROVIDER_METHODS = {
  adyen: 'card',
  trustly: 'trustly',
  swish: 'swish',
} as const;

type PaymentMethod = 'card' | 'swish' | 'trustly' | 'wellness';
export type PaymentMethods = Array<PaymentMethod>;

type GetAdyenConfigParams = {
  currency: string;
  locale: string;
  amount?: number;
  methods: PaymentMethods;
  storedPaymentMethod?: StoredPaymentMethod;
};

const getTranslations = (paymentMethods: PaymentMethods) => {
  let text = t`payment_modal.payment_method.submit_button`;

  if (paymentMethods.includes('trustly')) {
    text = t`payment_modal.payment_method.trustly.submit_button`;
  } else if (paymentMethods.includes('swish')) {
    text = t`payment_modal.payment_method.swish.submit_button`;
  }

  return {
    'sv-SE': {
      continueTo: text,
    },
    'no-NO': {
      continueTo: text,
    },
  };
};

function getAdyenLocal(local) {
  const locals = {
    se: 'sv-SE',
    no: 'no-NO',
    fi: 'fi-FI',
    en: 'en-US',
  };

  return locals[local] || 'en_US';
}

const getAdyenConfig = ({
  currency,
  locale,
  methods,
  amount = 0,
  storedPaymentMethod,
}: GetAdyenConfigParams) => {
  const paymentMethodsResponse = {
    groups: [],
    paymentMethods: [],
  };

  if (methods.includes('trustly') && locale === 'se') {
    paymentMethodsResponse.paymentMethods.push({
      name: 'Trustly',
      supportsRecurring: true,
      type: 'trustly',
    });
  }

  if (methods.includes('card')) {
    paymentMethodsResponse.groups.push({
      name: t`payment_modal.payment_method.card.label`,
      types: ['maestro', 'mc', 'visa', 'visadankort'],
    });
    paymentMethodsResponse.paymentMethods.push({
      name: t`payment_modal.payment_method.card.label`,
      brands: ['maestro', 'mc', 'visa', 'visadankort'],
      details: [
        {
          key: 'encryptedCardNumber',
          type: 'cardToken',
        },
        {
          key: 'encryptedSecurityCode',
          type: 'cardToken',
        },
        {
          key: 'encryptedExpiryMonth',
          type: 'cardToken',
        },
        {
          key: 'encryptedExpiryYear',
          type: 'cardToken',
        },
        {
          key: 'holderName',
          optional: true,
          type: 'text',
        },
      ],
      type: 'scheme',
    });

    if (storedPaymentMethod) {
      paymentMethodsResponse.paymentMethods = paymentMethodsResponse.paymentMethods.reverse();
    }
  }

  if (methods.includes('swish')) {
    paymentMethodsResponse.paymentMethods.push({
      name: 'Swish',
      type: 'swish',
    });
  }

  return {
    clientKey: process.env.ADYEN_CLIENT_KEY,
    environment: process.env.ADYEN_ENVIRONMENT,
    paymentMethodsResponse,
    locale: getAdyenLocal(locale),
    translations: getTranslations(methods),
    showPayButton: true,
    paymentMethodsConfiguration: {
      card: {
        ...(storedPaymentMethod?.provider === 'adyen'
          ? {
              holderName: storedPaymentMethod.name,
              storedPaymentMethodId: storedPaymentMethod.id,
              lastFour: storedPaymentMethod.lastFour,
              type: String(storedPaymentMethod.variant).toLowerCase(),
              expiryMonth: storedPaymentMethod.expiryMonth,
              expiryYear: storedPaymentMethod.expiryYear,
            }
          : {}),
        hasHolderName: true,
        holderNameRequired: true,
        amount: {
          // value takes amount in cents
          // JS issue: decimals loses precision on some operations
          // so we round it, since we multiply by 100
          value: Math.round(amount * 100),
          currency,
        },
      },
    },
  };
};

type AdyenFormProps = {
  loading: boolean;
  amount: number;
  currency?: string;
  onSubmit(arg0: flexibleObject): void;
  paymentError: boolean;
  enabledPaymentMethods: PaymentMethods;
  paymentEnabled?: boolean;
  showStoredMethods?: Boolean;
  onClose?(): void;
  displayAs?: 'modal' | 'inline';
  chooseMethodButtonText?: React.ReactNode;
  wellnessForm?: React.ReactNode;
};

const AdyenForm = (props: AdyenFormProps) => {
  const {
    loading,
    amount,
    currency: _currency,
    onSubmit,
    paymentError,
    enabledPaymentMethods,
    showStoredMethods = true,
    onClose,
    displayAs = 'modal',
    // if false we only use the modal to select method or add new (no cvc prompt)
    paymentEnabled = true,

    //
    wellnessForm,
  } = props;

  const [flags] = useFlags();
  const [currentUser] = useContext(UserContext);
  const _enabledPaymentMethods = enabledPaymentMethods.filter(item =>
    !currentUser?.canUseTrustly || flags.disable_trustly
      ? item !== 'trustly'
      : true
  );

  const locale = useContext(LanguageContext);
  const [showPaymentForm, setShowPaymentForm] = useState(!showStoredMethods);
  const [
    selectedStoredPaymentMethod,
    setSelectedStoredPaymentMethod,
  ] = useState<StoredPaymentMethod>(null);

  const { data, loading: paymentMethodsLoading } = useMyPaymentMethodsQuery({
    onCompleted(data) {
      const usableMethods = getUsablePaymentMethods(data.myPaymentMethods);

      if (usableMethods?.length === 0) {
        setShowPaymentForm(true);
      }
    },
  });

  //
  const currency = _currency ?? (['en', 'fi'].includes(locale) ? 'EUR' : 'SEK');

  const getUsablePaymentMethods = useCallback(function(
    methods: MyPaymentMethodsQuery['myPaymentMethods']
  ) {
    return methods?.filter(item => {
      if (
        item.provider === ProviderKind['Trustly'] &&
        flags.disable_saved_trustly_payments === true
      ) {
        return false;
      } else if (
        !_enabledPaymentMethods.includes(PROVIDER_METHODS[item.provider])
      ) {
        return false;
      }

      return true;
    });
  },
  []);

  function handleOnSubmit(state) {
    if (state.isValid) {
      const {
        type,
        storedPaymentMethodId,
        ...paymentMethod
      } = state.data.paymentMethod;

      const variables = {
        paymentMethod: { ...paymentMethod, providerType: type },
        paymentMethodId: storedPaymentMethodId,
        riskData: state.data.riskData,
        browserInfo: state.data.browserInfo,
      };
      onSubmit(variables);
    }
  }

  function setupAdyenCheckout(onConfigSuccess) {
    // library uses window so we lazy load it
    const AdyenCheckout = window['AdyenCheckout'];

    let methods = _enabledPaymentMethods;

    // for now we only need to show card payments within adyen-dropin for cvc
    if (selectedStoredPaymentMethod || !currentUser?.canUseTrustly) {
      methods = ['card'];
    }

    const configuration = {
      ...getAdyenConfig({
        currency,
        amount,
        locale,
        methods,
        storedPaymentMethod: selectedStoredPaymentMethod,
      }),
      onSubmit: handleOnSubmit,
      onConfigSuccess,
    };

    const checkout = new AdyenCheckout(configuration);
    return checkout.create('dropin').mount('#component-container');
  }

  useEffect(() => {
    if (showPaymentForm) {
      let onConfigSuccess = false,
        instance = null;

      if (window['AdyenCheckout']) {
        instance = setupAdyenCheckout(() => {
          onConfigSuccess = true;
        });
      } else {
        window.addEventListener('ADYEN_LOADED', () => {
          instance = setupAdyenCheckout(() => {
            onConfigSuccess = true;
          });
        });
      }

      return () => {
        if (onConfigSuccess) {
          instance.unmount();
        }
      };
    }
  }, [showPaymentForm]);

  const onContinueToPayment = (item: StoredPaymentMethod = null) => {
    if (item && (paymentEnabled === false || item?.provider === 'trustly')) {
      //
      onSubmit({
        paymentMethod: { providerType: item.provider },
        paymentMethodId: item.id,
        riskData: null,
        browserInfo: null,
      });
    } else {
      setSelectedStoredPaymentMethod(item);
      setShowPaymentForm(true);
    }
  };

  if (!showPaymentForm && data?.myPaymentMethods?.length > 0) {
    const myPaymentMethods = getUsablePaymentMethods(data?.myPaymentMethods);

    if (myPaymentMethods?.length > 0) {
      let useOtherMethodButtonText = (
        <Trans id="payment_modal.use_other_methods.default_text" />
      );

      [
        {
          methods: ['trustly', 'card', 'wellness'] as const,
          text: (
            <Trans id="payment_modal.use_other_methods.card_trustly_epassi" />
          ),
        },
        {
          methods: ['trustly', 'card'] as const,
          text: <Trans id="payment_modal.use_other_methods.card_trustly" />,
        },
        {
          methods: ['swish', 'card', 'wellness'] as const,
          text: (
            <Trans id="payment_modal.use_other_methods.card_swish_epassi" />
          ),
        },
        {
          methods: ['swish', 'card'] as const,
          text: <Trans id="payment_modal.use_other_methods.card_swish" />,
        },
      ].some(item => {
        if (
          item.methods.every(method => _enabledPaymentMethods.includes(method))
        ) {
          useOtherMethodButtonText = item.text;
          return true;
        }
        return false;
      });

      return (
        <ChoosePaymentMethodModal
          amount={amount}
          currency={currency}
          paymentMethods={myPaymentMethods}
          onClose={onClose}
          onContinueToPayment={onContinueToPayment}
          disaplyAs="inline"
          useOtherMethodButtonText={useOtherMethodButtonText}
        />
      );
    }
  }

  return (
    <>
      {/* must stay wrapper in fragment because backbutton relys on order on parent */}
      {data?.myPaymentMethods?.length > 0 && showStoredMethods ? (
        <BackButton
          appearance="dark"
          link
          onClick={() => {
            setShowPaymentForm(false);
          }}
        >
          <Icon name="ygb-icon-Back-arrow-ic" />
          <Trans id="metadata.go_back" />
        </BackButton>
      ) : null}
      {loading ||
      (paymentMethodsLoading &&
        !data?.myPaymentMethods &&
        showStoredMethods) ? (
        <Loading data-testid="AdyenFormModal--Loading">
          <Spinner size={28} />
        </Loading>
      ) : null}
      {paymentError ? (
        <Banner
          data-testid="AdyenFormModal--Error"
          style={{
            marginBottom: '30px',
            color: '#C20015',
            backgroundColor: '#FFE7E7',
          }}
          disableIcon
        >
          <span>
            <Trans
              id="metadata.payment.failed"
              message="We’re sorry, an error has occurred with your payment method. Verify that your credit card details are correct and please try again."
              components={[
                <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>,
              ]}
            />
          </span>
        </Banner>
      ) : null}
      <AdyenContainer
        id="component-container"
        data-testid="AdyenFormModal--Container"
      ></AdyenContainer>
      {wellnessForm}
    </>
  );
};

export default AdyenForm;
