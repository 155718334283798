import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Body, Header, Modal } from './base';
import LoginForm, { Title } from '@ecosystems/login/LoginForm';

const Form = styled(LoginForm)`
  ${Title} {
    margin-top: 0px;
  }
`;

export declare type LoginModalProps = {
  open?: boolean;
  callBack?: (arg0: () => void) => void;
  openByDefault?: boolean;
  onClose?: () => void;
  paymentType?: string;
  noRegister?: boolean;
  message?: string;
  registerUrlParams?: Record<string, any>;
  children?(openModal: () => void, closeModal: () => void): React.ReactElement;
};

declare type Props = LoginModalProps;

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const LoginModal = (props: Props) => {
  const prevOpenProps = usePrevious(props.open);
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      // we can delay this until after re-render
      // because onClose is used to clean url params & it slows re-render for some reason
      props.onClose?.();
    });
  };

  const closeModalRef = useRef(
    typeof props.callBack === 'function'
      ? props.callBack.bind(null, closeModal)
      : null
  );

  useEffect(() => {
    // TODO: investigate this `openByDefault`
    const { openByDefault } = props;
    let timeout;
    if (openByDefault) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 2000);
    }
    return clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // previously the component relies only on its internal state
    // we watch open props to make component controllable from ouside
    // mainly to support usage in a hook
    if (prevOpenProps !== props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  const openModal = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        handleClose={() => {
          closeModal();
        }}
        data-testid="LoginModal"
      >
        <Header handleClose={() => closeModal()}></Header>
        <Body>
          <Form
            message={props.message}
            noRegister={props.noRegister}
            callBack={closeModalRef?.current}
            registerUrlParams={props.registerUrlParams}
          />
        </Body>
      </Modal>
      {props.children?.(openModal, closeModal)}
    </React.Fragment>
  );
};

export default LoginModal;
