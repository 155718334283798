import React, { useContext, useEffect, useState } from 'react';
import Styled from 'styled-components';
import autocomplete from '@components/form/autocomplete';
import LanguageContext from '@lib/contexts/languageContext';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const Autocomplete = Styled(autocomplete)<{ errorStyle?: string }>`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.interactive['color-18']};
  border-color:  ${props =>
    props.errorStyle && props.theme.colors.secondary['red-1']};
  border-radius: 5px;
  margin-top: 10px;
  background-color: ${props => props.theme.colors.neutrals.white};
  outline: none;
  cursor: pointer;

  .MuiInputBase-root{
    font-size: 14px;
  }

  &.Mui-focused {
    border: 1px solid #129edc;
  }

  .MuiAutocomplete-clearIndicatorDirty {
    display: none;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px;
  }
`;

declare type Props = {
  countries?: null | Array<Country>;
  name?: string;
  className?: string;
  onChange?(value: Country): void;
  defaultCountry?: Country;
  country?: Country;
};

const CountryAutoComplete = (props: Props) => {
  const {
    countries,
    className,
    name,
    country,
    onChange,
    defaultCountry,
  } = props;
  const lang = useContext(LanguageContext);
  const [_countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(country);

  useEffect(() => {
    if (countries && _countries !== countries) {
      setCountries(countries);
    }
  }, [countries]);

  useEffect(() => {
    setSelectedCountry(country);
  }, [country]);

  useEffect(() => {
    if (!countries) {
      // eslint-disable-next-line
      const countries = require(`@lib/countries/${lang}.json`);
      setCountries(countries);
      setSelectedCountry(countries[0]);
      onChange(countries[0]);
    }
  }, []);

  if (!_countries) {
    return <Autocomplete options={[]} value="" />;
  }

  return (
    <Autocomplete
      name={name}
      className={className}
      options={_countries}
      defaultValue={defaultCountry}
      onChange={(value: Country) => {
        if (value?.code) {
          setSelectedCountry(value);
          onChange?.(value);
        }
      }}
      onBlur={e => {
        // if user types an invalid country name, we reset to latest valid choice
        if (
          e.target instanceof HTMLInputElement &&
          e.target.value !== selectedCountry?.label
        ) {
          onChange(selectedCountry);
        }
      }}
      getOptionDisabled={(option: Country) => !option.code}
      getOptionLabel={(option: Country) => option.label}
      getOptionSelected={(option: Country, country) =>
        option.code === country?.code
      }
      renderOption={(option: Country) =>
        option.code ? (
          <React.Fragment>
            <span className="pr-1">{countryToFlag(option.code)}</span>{' '}
            {option.label}
          </React.Fragment>
        ) : (
          option.label
        )
      }
      value={selectedCountry}
      data-testid="CountryAutoComplete"
    />
  );
};

export default CountryAutoComplete;
