import React, { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import LanguageContext from '@lib/contexts/languageContext';
import MenuContent, { Title, Description, Anchor } from './MenuContent';

const Menu = styled(MenuContent)`
  left: 10%;
`;

const text = {
  overview: {
    title: <Trans id="global_menu.play.overview.title" />,
    desc: <Trans id="global_menu.play.overview.desc" />,
  },
  programs: {
    title: <Trans id="global_menu.play.programs.title" />,
    desc: <Trans id="global_menu.play.programs.desc" />,
  },
  video_audio: {
    title: <Trans id="global_menu.play.video_audio.title" />,
    desc: <Trans id="global_menu.play.video_audio.desc" />,
  },
  yogobe_challenge: {
    title: <Trans id="global_menu.play.yogobe_challenge.title" />,
    desc: <Trans id="global_menu.play.yogobe_challenge.desc" />,
  },
  playlists: {
    title: <Trans id="global_menu.play.playlists.title" />,
    desc: <Trans id="global_menu.play.playlists.desc" />,
  },
  bundles: {
    title: <Trans id="global_menu.play.needs_and_themes.title" />,
    desc: <Trans id="global_menu.play.needs_and_themes.desc" />,
  },
};

const PlayMenu = ({ className = '', open = false }) => {
  const ref = React.createRef<HTMLDivElement>();
  const lang = useContext(LanguageContext);

  return (
    <Menu
      ref={ref}
      className={className}
      open={open}
      items={[
        <Anchor key="1" href={`/${lang}/videos/overview`} prefetch={false}>
          <Title>{text.overview.title}</Title>
          <Description>{text.overview.desc}</Description>
        </Anchor>,
        <Anchor key="2" href={`/${lang}/videos/genres/all`} prefetch={false}>
          <Title>{text.video_audio.title}</Title>
          <Description>{text.video_audio.desc}</Description>
        </Anchor>,
        <Anchor key="3" href={`/${lang}/playlists`} prefetch={false}>
          <Title>{text.playlists.title}</Title>
          <Description>{text.playlists.desc}</Description>
        </Anchor>,
        <Anchor key="4" href={`/${lang}/programs`} prefetch={false}>
          <Title>{text.programs.title}</Title>
          <Description>{text.programs.desc}</Description>
        </Anchor>,
        <Anchor key="5" href={`/${lang}/challenge`} prefetch={false}>
          <Title>{text.yogobe_challenge.title}</Title>
          <Description>{text.yogobe_challenge.desc}</Description>
        </Anchor>,
        <Anchor key="6" href={`/${lang}/needs-and-themes`} prefetch={false}>
          <Title>{text.bundles.title}</Title>
          <Description>{text.bundles.desc}</Description>
        </Anchor>,
      ]}
    />
  );
};

export default PlayMenu;
