import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import LanguageContext from '@lib/contexts/languageContext';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import Button from '@components/buttons';
import Label from '@components/form/Label';
import { Box, Container, Flex } from '@components/layout/Grid';
import { FooterLink } from '@components/Link';
import {
  CopyrightFooter,
  LanguagesMenuFooter,
  LIFooter,
  ULFooter,
} from '@components/texts';
import footerLinks from '@lib/footerLinks';
import { ANDROID_APP_LINK, IOS_APP_LINK, LANGUAGES } from '@lib/constants';

const Wrapper = styled.footer`
  color: white;
  border: 1px solid transparent;
  background-color: ${props => props.theme.colors.secondary['gray-5']};
`;

const FooterSectionLabel = styled(Label)`
  color: white;
  font-weight: bold;
  cursor: default;
`;

const MobileStoreImage = styled.img`
  object-fit: contain;
`;

const Languages = () => {
  const router = useRouter();

  const switchLocale = lang => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, lang },
    });
  };

  return (
    <LanguagesMenuFooter>
      <Trans id="footer.languages.label" message={'Language'} />:{` `}
      {LANGUAGES.map(({ lang, trans }, i) => (
        <React.Fragment key={lang}>
          {i > 0 ? (
            <>
              {` `}|{` `}
            </>
          ) : null}
          <Button
            appearance="link"
            color="white"
            onClick={() => {
              switchLocale(lang);
            }}
          >
            {trans}
          </Button>
        </React.Fragment>
      ))}
    </LanguagesMenuFooter>
  );
};

const footerSections = {
  yogobeLinks: <Trans id="footer.links.yogobe" />,
  discoverLinks: <Trans id="footer.links.discover" />,
  communityLinks: <Trans id="footer.links.community" />,
  socialMediaLinks: <Trans id="footer.label2" />,
  servicesLinks: <Trans id="footer.label3" />,
};

const Footer = () => {
  const locale = useContext(LanguageContext);

  return (
    <Wrapper>
      <Container flexDirection="column" my={[0, 20]} px={[15, 20, 20]} pt={20}>
        <Flex
          width={1}
          mb={30}
          order={[2, 1, 1]}
          flexDirection={['column', 'row', 'row']}
          justifyContent={['flex-start', 'space-between', 'space-between']}
          alignItems={['flex-start', 'center', 'center']}
          flexWrap="wrap"
        >
          <Languages />
          <Box mt={10}>
            <a
              href={ANDROID_APP_LINK}
              target="_blank"
              rel="noreferrer noopener"
            >
              <MobileStoreImage
                src={
                  locale === 'se'
                    ? '/static/google-play-badge-SE.png'
                    : '/static/google-play-badge-en.png'
                }
                width="100px"
                height="30px"
                loading="lazy"
              />
            </a>
            <a href={IOS_APP_LINK} target="_blank" rel="noreferrer noopener">
              <MobileStoreImage
                src={
                  locale === 'se'
                    ? '/static/App_Store_Badge_se.svg'
                    : '/static/App_Store_Badge_en.svg'
                }
                width="120px"
                height="30px"
                loading="lazy"
              />
            </a>
          </Box>
        </Flex>
        <Container flexWrap="wrap" order={[1, 2, 2]}>
          {Object.entries(footerSections).map(([key, trans]) => {
            const links = footerLinks[locale][key] || [];
            return links.length > 0 ? (
              <Box
                key={key}
                mb={[30, 30, 0]}
                pr={[0, 20]}
                width={[1, 1 / 2, 1 / 5]}
                minWidth="15em"
              >
                <FooterSectionLabel as="p">{trans}</FooterSectionLabel>
                <ULFooter>
                  {links.map((item, index) => {
                    return (
                      <LIFooter key={index}>
                        <FooterLink href={Object.values(item)[0]}>
                          {Object.keys(item)[0]}
                        </FooterLink>
                      </LIFooter>
                    );
                  })}
                </ULFooter>
              </Box>
            ) : (
              []
            );
          })}
        </Container>
      </Container>
      <Container
        px={[25, 20, 20]}
        pb={[10, 20]}
        justifyContent={['start', 'center']}
      >
        <CopyrightFooter>
          <Trans
            id="footer.copyright"
            message={'©YOGOBE 2020. All rights reserved.'}
            values={{ year: new Date().getFullYear() }}
          />
        </CopyrightFooter>
      </Container>
    </Wrapper>
  );
};

export default Footer;
