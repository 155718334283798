import styled from 'styled-components';
import Icon from '@components/Icon';
import { Flex } from '@components/layout/Grid';
import { cn } from '@lib/tw';

const CheckMark = styled(Icon)``;

export const BulletsList = styled(Flex)`
  gap: 20px;
  color: #242424;
  margin-right: 20px;

  i[name='ygb-icon-checkmark'] {
    font-size: 16px;
    margin-right: 8px;

    &:before {
      color: ${({ highlightColor, theme }) =>
        highlightColor || theme.colors.primary['blue-2']};
    }
  }
`;

export const BulletItem = styled.p`
  text-align: center;
  margin: 0;
`;

export const Bullets = ({ className = '', bullets }) => {
  return (
    <BulletsList
      className={cn(className, 'flex flex-col flex-wrap xs:flex-row')}
      px="20px"
      py="8px"
    >
      {bullets.map((item, i) => (
        <BulletItem key={i}>
          <CheckMark
            className="hidden xs:inline-block"
            name="ygb-icon-checkmark"
          />
          {item}
        </BulletItem>
      ))}
    </BulletsList>
  );
};
