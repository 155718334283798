import React from 'react';
import Styled, {
  FlattenSimpleInterpolation,
  DefaultTheme,
  ThemeProps,
  FlattenInterpolation,
} from 'styled-components';
import footer from '@components/footer.register';
import icon from '@components/Icon';

export const Footer = footer;
const Icon = Styled(icon)<{ whiteOnDesktop?: boolean }>`
  ${props =>
    props.whiteOnDesktop
      ? `
      @media (min-width: ${props.theme.viewports.laptop}px) {
        :before {
          color: ${props.theme.colors.neutrals.white};
          text-shadow: 0 1px 4px #000000bf;
        }
      }
  `
      : ''}
`;

// we allow style extention because
// logo changes color on some viewports
export const Logo = ({ className = '', ...props }) => {
  return (
    <Icon
      fontSize="24px"
      name="ygb-icon-yogobe-logo2019_1"
      className={className}
      {...props}
    />
  );
};

export const Wrapper = Styled.div<{
  customStyle?:
    | string
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>;
}>`
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    flex-direction: row;
  }

  ${({ customStyle }) => customStyle}
`;

// abstraction for Left/Right containers
const Side = Styled.div<{
  customStyle?:
    | string
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>;
}>`
  ${({ customStyle = '' }) => customStyle}
`;

export const LeftContainer = Styled(Side)`
  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    flex: 5;
  }  
`;

export const RightContainer = Styled(Side)`
  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    flex: 6;
  }
`;

// TODO: enhance original paragraph in typography
export const Paragraph = Styled.p<{
  size?: number;
  lineHeight?: number;
  center?: boolean;
  weight?: number | string;
  crossed?: boolean;
  customStyle?:
    | string
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>;
  mr?: number | 'auto';
  mt?: number | 'auto';
  ml?: number | 'auto';
  mb?: number | 'auto';
}>`
  color: ${({ color }) => (color ? color : '#242424')};
  font-size: ${({ size }) => (size ? size : 20)}px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 28)}px;
  font-weight: ${({ weight }) => (weight ? weight : '500')};
  // we dont have any right align text
  // in case we need it, convert this into textAlign
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  ${({ crossed }) => (crossed ? 'text-decoration: line-through;' : '')}
  margin: 10px 0;
  // doing hasOwnProperty because 0 will give a false negative
  ${props => (props.hasOwnProperty('mt') ? `margin-top: ${props.mt}px;` : '')}
  ${props => (props.hasOwnProperty('mr') ? `margin-right: ${props.mr}px;` : '')}
  ${props =>
    props.hasOwnProperty('mb') ? `margin-bottom: ${props.mb}px;` : ''}
  ${props => (props.hasOwnProperty('ml') ? `margin-left: ${props.ml}px;` : '')}
  
  ${({ customStyle }) => customStyle}
`;

// This file should probably not be changed anymore
// we have two templates, FormTemplate and ConfirmationTemplate
const RegisterLayout = React.memo(({ children }: { children: Function }) => {
  return children({
    Wrapper,
    LeftContainer,
    RightContainer,
    Paragraph,
    Footer,
    Logo,
  });
});

export default RegisterLayout;
