import { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { format } from 'date-fns/format';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import {
  Header as header,
  Body,
  Footer as footer,
  Modal as modal,
} from '@organisms/modals/base';
import { Box, Flex } from '@components/layout/Grid';
import SubscriptionOption from '@components/SubscriptionOption';
import { getSubscriptionText } from '@ecosystems/register/subscription/SubscriptionStep';
import { Striked } from '@components/texts';
import {
  OrderStatus,
  PayForItemPayload,
  PricingSubscriptionFieldsFragment,
  SubscriptionStatus,
  useAvailableAllCodesQuery,
  useMyPaymentMethodsQuery,
  useOrderQuery,
  useStartSubscriptionMutation,
} from '@gql/generated';
import { Button } from '@components/ButtonV2';
import Icon from '@components/Icon';
import { ArrowRightIcon as arrowRightIcon } from '@assets/index';
import { Bullets, BulletItem } from '@ecosystems/pricing/BulletList';
import BaseSkeleton from '@skeletons/BaseSkeleton';
import { UserContext } from '@lib/contexts/UserProvider';
import { snackbarVar } from '@lib/apollo/policies';
import { useMedia } from 'use-media';
import { useAddPaymentMethodModal } from '@lib/hooks/useAddPaymentMethodModal';

const StyledSubscriptionOption = styled(SubscriptionOption)`
  margin-top: 10px;
`;

const Modal = styled(modal)<{ zIndex?: number }>`
  z-index: ${({ zIndex = 1301 }) => zIndex} !important;
`;

const Header = styled(header)`
  z-index: 9;
`;

const BulletsList = styled(Bullets)`
  gap: 10px 30px;
  padding: 0;
  margin: 20px 0;

  ${BulletItem} {
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 500;

    i:before {
      font-size: 13px;
      color: #129edc;
    }
  }
`;

const Title = styled.h3`
  color: #151515;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
`;

const InfoText = styled.p`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 23px;
  margin: 15px 0 0;

  i {
    margin-right: 5px;
    opacity: 0.4;
  }
`;

const Footer = styled(footer)`
  padding: 20px 10px;

  > div {
    gap: 10px;
  }

  button,
  a {
    flex: 1;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;

    :last-of-type {
      flex-grow: 2;
    }

    svg {
      display: none;
      margin: 0 0 0 25px;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    padding: 20px 50px;

    button,
    a {
      min-width: 40%;
      width: auto;

      svg {
        display: initial;
      }
    }
  }
`;

const ArrowRightIcon = styled(arrowRightIcon)`
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 4px;
  margin: 0 0 0 auto;

  path {
    fill: #fff;
  }
`;

type SubscriptionProps = {
  data?: PricingSubscriptionFieldsFragment[];
  selectedSubscription: PricingSubscriptionFieldsFragment;
  onSelect(subscriptionName: PricingSubscriptionFieldsFragment): void;
};
const Subscriptions = ({
  data,
  onSelect,
  selectedSubscription,
}: SubscriptionProps) => {
  return (
    <form
      onChange={e => {
        onSelect(e.target['value']);
      }}
    >
      <Flex flexDirection="column" py={0}>
        {data?.map(subscription => {
          const { title, description } = getSubscriptionText(subscription);

          let oldPrice;
          if (
            // if the susbcription has a discount we show the old price crossed
            subscription.discountedAmount &&
            subscription.discountedAmount !== subscription.amount
          ) {
            oldPrice = (
              <Striked>{subscription.amount + subscription.currency}</Striked>
            );
          }

          // because global subscriptions have same subscriptionName as basic plans
          // we check that the subscription is not a global
          // const SubscriptionCode = ['global', 'extra_global'].includes(
          //   subscription.promotionKind
          // )
          //   ? subscription.promotionName
          //   :
          //   subscription.subscriptionName
          const subscriptionName = subscription.subscriptionName;

          return (
            <StyledSubscriptionOption
              key={subscriptionName}
              name="subscriptionPlan"
              value={subscriptionName}
              title={
                <>
                  {title} &nbsp; {oldPrice}
                </>
              }
              desc={description}
              selected={
                subscriptionName === selectedSubscription?.subscriptionName
              }
              data-testid={`SubscriptionOption--${subscription.subscriptionName}`}
            />
          );
        })}
      </Flex>
    </form>
  );
};

const CardSkeleton = () => (
  <Flex my={20} flexDirection="column">
    <BaseSkeleton height={80} marginBottom={10} animation />
    <BaseSkeleton height={80} marginBottom={10} animation />
    <BaseSkeleton height={80} marginBottom={10} animation />
  </Flex>
);

const PLANS = {
  1: 'monthly',
  3: 'quarterly',
  12: 'yearly',
};

declare type Props = {
  open?: boolean;
  onPaymentComplete?(arg0: PayForItemPayload): void;
  handlePaymentCompletion?(arg0: PayForItemPayload): void;
  onPaymentSubmit?(): void;
};

const WellnessUpsellModal = (props: Props) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const large = useMedia(`(min-width: ${theme?.viewports?.tablet}px)`);
  const [selectedPlan, setSelectedPlan] = useState<
    PricingSubscriptionFieldsFragment
  >(null);
  const router = useRouter();
  const [currentUser, { refetch }] = useContext(UserContext);
  //
  const {
    data: myPaymentMethodsData,
    refetch: reloadMethods,
  } = useMyPaymentMethodsQuery();
  //
  const [
    add_method_modal,
    { openModal: openAddMethod, closeModal: closeAddMethod },
  ] = useAddPaymentMethodModal({
    zIndex: 1302,
    redirectTo: router.asPath,
    onPaymentMethodAdded() {
      reloadMethods();
      closeAddMethod();
      onStartSubscription();
    },
  });
  //
  const { data: order_data } = useOrderQuery({
    variables: {
      id: router.query?.order_id as string,
    },
  });
  //
  const [startSubscription, { loading }] = useStartSubscriptionMutation();
  const { data } = useAvailableAllCodesQuery({
    onCompleted(resp) {
      const sub = resp.availableAllCodes.subscriptions.find(
        sub => sub.subscriptionName === 'monthly'
      );

      if (sub) {
        setSelectedPlan(sub);
      }
    },
  });

  const onSelect = subscriptionName => {
    const plan = data?.availableAllCodes?.subscriptions.find(
      sub => sub.subscriptionName === subscriptionName
    );
    if (plan) {
      setSelectedPlan(plan);
    }
  };

  const onStartSubscription = async () => {
    const resp = await startSubscription({
      variables: {
        planType: PLANS[selectedPlan?.months],
      },
    });

    if (resp.data?.startSubscription?.success) {
      snackbarVar({
        snackBarMessage: t({
          id: 'wellness_upsell_modal.subscription_activated_msg',
          values: {
            date: format(new Date(currentUser?.accessUntil), 'dd-MM-yyyy'),
          },
        }),
        isSnackBarOpen: true,
      });
      setOpen(false);
      refetch({ onlyBaseProperties: true });
    }
  };

  if (
    (currentUser?.subscription?.status === SubscriptionStatus['Active'] &&
      currentUser?.paymentTypeRenews) ||
    !(
      order_data?.order?.__typename === 'WellnessPackagePayment' &&
      order_data?.order?.status === OrderStatus['Completed']
    )
  ) {
    return null;
  }

  const accessUntil = format(new Date(currentUser.accessUntil), 'dd-MM-yyyy');

  const button_text = {
    1: <Trans id="wellness_upsell_modal.buttons.start_monthly_plan_btn" />,
    3: <Trans id="wellness_upsell_modal.buttons.start_quarterly_plan_btn" />,
    12: <Trans id="wellness_upsell_modal.buttons.start_yearly_plan_btn" />,
  }[selectedPlan?.months];

  return (
    <>
      <Modal open={open}>
        <Header
          handleClose={() => {
            setOpen(false);
          }}
        />
        <Body mt={[0, 0, -50]} pb={30}>
          <Title>
            <Trans id="wellness_upsell_modal.title" />
          </Title>
          <p>
            <Trans id="wellness_upsell_modal.body" values={{ accessUntil }} />
          </p>
          <Box mb={20}>
            <BulletsList
              bullets={[
                <Trans id="wellness_upsell_modal.items.item1" />,
                <Trans id="wellness_upsell_modal.items.item2" />,
                <Trans id="wellness_upsell_modal.items.item3" />,
              ]}
            />
          </Box>
          {loading ? (
            <CardSkeleton />
          ) : (
            <Subscriptions
              data={data?.availableAllCodes?.subscriptions}
              onSelect={onSelect}
              selectedSubscription={selectedPlan}
            />
          )}
          {selectedPlan ? (
            <InfoText>
              <Icon name="ygb-icon-infob" fontSize="15px" />
              <Trans
                id="wellness_upsell_modal.info_text"
                values={{
                  currency: selectedPlan.currency,
                  amount:
                    (selectedPlan?.discountedAmount > 0
                      ? selectedPlan?.discountedAmount
                      : selectedPlan?.amount) +
                    ' ' +
                    selectedPlan.currency,
                  accessUntil,
                }}
              />
            </InfoText>
          ) : null}
        </Body>
        <Footer>
          <Button
            appearance="blue"
            outline
            rounded
            size={large ? 'large' : 'small'}
            onClick={() => setOpen(false)}
          >
            <Trans id="wellness_upsell_modal.buttons.no_thanks_btn" />
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              if (myPaymentMethodsData?.myPaymentMethods?.length > 0) {
                onStartSubscription();
              } else {
                openAddMethod();
              }
            }}
            appearance="blue"
            size={large ? 'large' : 'small'}
            rounded
          >
            {button_text}
            <ArrowRightIcon />
          </Button>
        </Footer>
      </Modal>
      {add_method_modal}
    </>
  );
};

export default WellnessUpsellModal;
