import React, { useContext, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Icon from '@components/Icon';
import { Container } from '@components/layout/Grid';
import theme from '@lib/theme';
import UserLoadingSkeleton from '@skeletons/UserLoadingSkeleton';
import { Login, Logo, User, Wrapper } from './subcomponents';
import UserLogin from './UserLogin';
import { UserContext } from '@lib/contexts/UserProvider';
import Menu from './menu';
import LanguageContext from '@lib/contexts/languageContext';
import { useRouter } from 'next/router';

const GlobalStyle = createGlobalStyle<{ isOpen?: boolean }>`
  ${({ isOpen }) =>
    isOpen
      ? `
          #__next {
            height: 100dvh;
            overflow-y: hidden;
          }

          ${Wrapper}:not(:first-child) {
            ${Menu.toString()} ul {
              margin: 115px 0 0;
            }
          }
        `
      : null}
`;

const HeaderContainer = styled(Container)`
  position: relative;
`;

const BurgerButton = styled.button`
  display: flex;
  width: 17px;
  border: none;
  padding: 0;
  margin-right: 10px;
  margin-left: 3px;
  background: none;
  outline: none;
  z-index: 2;

  @media (min-width: ${({ theme }) => theme.viewports.mobile}px) {
    margin-right: 20px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: none;
  }
`;

const BurgerMenuIcon = ({ isOpen }) => {
  return isOpen ? (
    <Icon name="ygb-icon-close" fontSize="15px" color="black" />
  ) : (
    <Icon name="ygb-icon-hamburgermenub" fontSize="15px" color="black" />
  );
};

const Header = () => {
  const router = useRouter();
  const lang = useContext(LanguageContext);
  const [currentUser, { loading }] = useContext(UserContext);
  const [openMenu, setOpenMenu] = useState(false);

  let _loadingState = false;
  if (!currentUser?.id && loading) {
    _loadingState = true;
  }

  useEffect(() => {
    setOpenMenu(false);
  }, [router.asPath]);

  const onLinkClick = () => {
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  return (
    <Wrapper>
      <HeaderContainer alignItems="center">
        <GlobalStyle isOpen={openMenu} />
        <BurgerButton onClick={() => setOpenMenu(open => !open)}>
          <BurgerMenuIcon isOpen={openMenu} />
        </BurgerButton>
        <Logo>
          <a
            href={`/${lang}`}
            style={{ display: 'flex', textDecoration: 'none' }}
          >
            <Icon
              name="ygb-icon-yogobe-logo2019_1"
              fontSize="20px"
              color={theme.colors.primary['blue-2']}
            />
          </a>
        </Logo>
        <Menu isOpen={openMenu} onLinkClick={onLinkClick} />
        <User>
          {_loadingState ? (
            <Login.AvatarWrapper>
              <UserLoadingSkeleton />
            </Login.AvatarWrapper>
          ) : (
            <UserLogin />
          )}
        </User>
      </HeaderContainer>
    </Wrapper>
  );
};

export default Header;
