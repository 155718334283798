import React, { useState, useEffect, useContext } from 'react';
import Styled from 'styled-components';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import * as Sentry from '@sentry/nextjs';
import { t } from '@lingui/macro';
import { Box, Flex } from '@components/layout/Grid';
import { UserContext } from '@lib/contexts/UserProvider';
import {
  CurrentUser,
  PaymentResultCodeKind,
  PaymentsStoreAdyenDropinMutation,
  PaymentsStoreTrustlyMutation,
  SubscriptionStatus,
  useMyPaymentMethodsLazyQuery,
  usePaymentsStoreAdyenDropinMutation,
  usePaymentsStoreTrustlyMutation,
  useStoreEpassiMutation,
} from '@gql/generated';
import { snackbarVar } from '@lib/apollo/policies';
import MessageStripe from '@components/MessageStripe';
import Icon from '@components/Icon';
import AdyenForm, { PaymentMethods } from './AdyenFormModal';
import '@adyen/adyen-web/dist/adyen.css';
import AnalyticsManager from '@lib/analytics/manager';
import useFlags from '@lib/hooks/useFlags';
import LanguageContext from '@lib/contexts/languageContext';
import { WellnessMethods } from './wellnessMethods';
import { prependHostName } from '@lib/utils';
import { useTrustlyIframeModal } from './useIframeModel';

const PaymentBanner = Styled(MessageStripe)`
  border-radius: 5px;
  padding: 10px 20px;

  i.ygb-icon-infob {
    margin-right: 5px;
  }

  p {
    line-height: 20px;
  }
`;

const PaymentBannerText = (currentUser: CurrentUser) => {
  if (
    currentUser?.subscription?.status === SubscriptionStatus['Active'] &&
    currentUser?.paymentTypeRenews
  ) {
    return <Trans id="payment_modal.banner.active_subscription_upgrade" />;
  } else if (
    currentUser?.subscription?.currentSubscriptionPlan?.planType === 'trial' &&
    currentUser?.isPremium
  ) {
    if (currentUser?.isEmailActivated) {
      // activated but still has premium
      return <Trans id="payment_modal.banner.trial_upgrade" />;
    } else {
      // not activated but still has premium
      return <Trans id="payment_modal.banner.unverified_trial_upgrade" />;
    }
  } else if (
    currentUser?.subscription?.status !== SubscriptionStatus['Active'] &&
    !currentUser?.isPremium
  ) {
    return <Trans id="payment_modal.banner.subscription_upgrade" />;
  } else {
    return <Trans id="payment_modal.banner.inactive_subscription_upgrade" />;
  }
};

export type PaymentPayloads =
  | PaymentsStoreTrustlyMutation['paymentsStoreTrustly']
  | PaymentsStoreAdyenDropinMutation['paymentsStoreAdyenDropin'];

/*
  IMPLEMENTATION REASONS:
  to avoid seeing the css taking effect, we load assets first
  we defer mounting the hook element (#component-container) until all assets are loaded
  then we render the hook el and when its mounted we initialize the adyen dropin
*/

type SubscriptionPaymentProps = {
  redirectTo?: string;
  redirectToSuccess?: string;
  redirectToFailed?: string;
  paymentType: string;
  amount: number;
  months: number;
  currency: string;
  sourcePage: string;
  code?: string;
  enableOneTimeMethods?: boolean;
  onPaymentComplete?(arg0: PaymentPayloads): void;
  onClose?(): void;
};

const SubscriptionPaymentForm = (props: SubscriptionPaymentProps) => {
  const {
    paymentType,
    amount,
    months,
    currency,
    code = null,
    // this is just a callback for doing something after the showing the confirmation
    onPaymentComplete,
    // used in registeration to redirect to confirmation page after trustly
    redirectTo = null,
    enableOneTimeMethods = true,
    sourcePage,
  } = props;
  const [
    iframePaymentModel,
    { open: openIframModel },
  ] = useTrustlyIframeModal();
  const lang = useContext(LanguageContext);
  const [currentUser, { refetch: reloadUser }] = useContext(UserContext);
  const router = useRouter();
  const [flags] = useFlags();
  const [loadEpassiData] = useStoreEpassiMutation();
  const [refetchStoredPaymentMethod] = useMyPaymentMethodsLazyQuery();
  const [paymentError, setPaymentError] = useState(false);
  const [
    runTrustlyPaymentRequest,
    { loading: trustlyRequestLoading, data: trustlyData },
  ] = usePaymentsStoreTrustlyMutation({
    onError(error) {
      setPaymentError(true);
      Sentry.captureException(new Error(error.message), {
        level: 'fatal',
        contexts: {
          user: {
            id: currentUser?.id,
          },
        },
      });
    },
  });

  const [
    runPaymentRequest,
    { loading: adyenRequestLoading, data: adyenData },
  ] = usePaymentsStoreAdyenDropinMutation({
    onError(error) {
      Sentry.captureException(new Error(error.message), {
        contexts: {
          user: {
            id: currentUser?.id,
          },
        },
      });
    },
  });

  useEffect(() => {
    // new GA4 event
    AnalyticsManager().sendSubscriptionPurchaseBeginEvent({
      months,
      value: amount,
      currency,
      plan: paymentType,
      content_type: router.query?.campaign as string,
      source_page: props.sourcePage,
      wellness: props.sourcePage === 'wellness',
    });
  }, []);

  const onComplete = (paymentData: PaymentPayloads, paymentMethod: string) => {
    AnalyticsManager().sendSubscriptionPurchaseCompleteEvent({
      order_id: paymentData.orderId,
      months,
      currency,
      value: amount,
      original_amount: null,
      payment_type: paymentMethod,
      source_page: props.sourcePage,
      wellness: sourcePage === 'wellness',
    });

    // show toaster
    snackbarVar({
      isSnackBarOpen: true,
      snackBarMessage: t`settings_subscriptions.updated`,
      // these should be made optional
      id: '',
      goTo: 'NONE',
    });

    // refresh user data
    reloadUser();
    //
    refetchStoredPaymentMethod({
      fetchPolicy: 'network-only',
    });

    onPaymentComplete(paymentData);
  };

  const send_tracking_events = () => {
    //
    AnalyticsManager().sendUpgradedPlanEvent({
      months,
      amount,
      currency,
      payment_type: paymentType,
      content_type: router.query?.campaign as string,
    });
  };

  const onClose = () => {
    props.onClose();
    if (
      trustlyData?.paymentsStoreTrustly?.orderId ||
      adyenData?.paymentsStoreAdyenDropin?.orderId
    ) {
      router.push((router.query.to as string) ?? `/${lang}/me/dashboard`);
    }
  };

  const handlePaymentSubmit = async variables => {
    setPaymentError(null);
    //
    send_tracking_events();

    try {
      if (variables.paymentMethod.providerType === 'trustly') {
        runTrustlyPaymentRequest({
          variables: {
            paymentType: paymentType,
            redirectTo: prependHostName(redirectTo),
            paymentMethodId: variables.paymentMethodId,
            code,
            sourcePage,
          },
        }).then(resp => {
          // handle exception
          if (resp?.data?.paymentsStoreTrustly?.errors?.length > 0) {
            setPaymentError(true);
            Sentry.captureEvent({
              message: 'Failed trustly subscription payment',
              level: 'fatal',
              contexts: {
                user: {
                  id: currentUser?.id,
                },
                response: {
                  ...(resp?.data.paymentsStoreTrustly || {}),
                  errors: resp?.data?.paymentsStoreTrustly.errors[0],
                },
              },
            });
          } else if (resp?.data.paymentsStoreTrustly.resultUrl) {
            if (flags.trustly_iframe) {
              openIframModel(resp?.data.paymentsStoreTrustly.resultUrl);
            } else {
              window.location.href = resp?.data.paymentsStoreTrustly.resultUrl;
            }
          } else if (resp?.data?.paymentsStoreTrustly?.orderId) {
            onComplete(resp?.data?.paymentsStoreTrustly, 'trustly');
          }
        });
      } else {
        runPaymentRequest({
          variables: {
            paymentType,
            redirectTo: prependHostName(redirectTo),
            code,
            sourcePage,
            ...variables,
          },
        }).then(resp => {
          // handle exception
          if (!resp?.data) {
            setPaymentError(true);
            return null;
          }

          //
          const resultCode = resp.data.paymentsStoreAdyenDropin?.resultCode;
          if (
            resp?.data?.paymentsStoreAdyenDropin?.errors?.length > 0 &&
            !resultCode
          ) {
            setPaymentError(true);
            Sentry.captureEvent({
              message: 'Failed subscription payment',
              contexts: {
                user: {
                  id: currentUser?.id,
                },
                response: {
                  ...resp?.data?.paymentsStoreAdyenDropin,
                  errors: resp?.data?.paymentsStoreAdyenDropin?.errors[0],
                },
              },
            });
          } else {
            if (resultCode === PaymentResultCodeKind['Refused']) {
              setPaymentError(true);
            } else if (
              [
                PaymentResultCodeKind['Authorised'],
                PaymentResultCodeKind['Pending'],
              ].includes(resultCode)
            ) {
              onComplete(resp.data?.paymentsStoreAdyenDropin, 'card');
            } else {
              const resultAction =
                resp.data?.paymentsStoreAdyenDropin?.resultAction;
              if (resultAction?.actionType === 'redirect') {
                if (String(resultAction?.httpMethod).toLowerCase() === 'post') {
                  try {
                    const redirectData = JSON.parse(resultAction.redirectData);
                    //
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = resultAction?.url;
                    //
                    const input1 = document.createElement('input');
                    input1.name = 'PaReq';
                    input1.value = redirectData['pa_req'];
                    //
                    const input2 = document.createElement('input');
                    input2.name = 'MD';
                    input2.value = redirectData['md'];
                    //
                    const input3 = document.createElement('input');
                    input3.name = 'TermUrl';
                    input3.value = redirectData['term_url'];
                    //
                    document.body.append(form);
                    form.append(input1, input2, input3);
                    form.submit();
                  } catch (ex) {
                    // show an error
                  }
                } else {
                  window.location.href = resultAction?.url;
                }
              }
            }
          }
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const paymentMethods: PaymentMethods = ['card'];
  if (!flags?.disable_trustly && currency === 'SEK') {
    paymentMethods.push('trustly');

    if (enableOneTimeMethods === true) {
      paymentMethods.push('wellness');
    }
  }

  return (
    <>
      <Flex flexDirection="column" mt={[0, 0, -40]}>
        <Box mb={10}>
          <PaymentBanner color="#242424" bg="#dff5ff" closable={false}>
            <Icon name="ygb-icon-infob" fontSize="16px" color="#ababab" />
            {PaymentBannerText(currentUser)}
          </PaymentBanner>
        </Box>
        <AdyenForm
          loading={trustlyRequestLoading || adyenRequestLoading}
          amount={amount}
          onSubmit={handlePaymentSubmit}
          paymentError={paymentError}
          enabledPaymentMethods={paymentMethods}
          onClose={onClose}
          wellnessForm={
            currency === 'SEK' && paymentMethods.includes('wellness') ? (
              <WellnessMethods
                getEpassiData={async () => {
                  const url = window.location.pathname;
                  const resp = await loadEpassiData({
                    variables: {
                      paymentType: paymentType,
                      redirectToSuccess:
                        props.redirectToSuccess || redirectTo || url,
                      redirectToFailed:
                        props.redirectToFailed || redirectTo || url,
                      sourcePage,
                    },
                  });
                  return resp.data.storeEpassi;
                }}
              />
            ) : null
          }
        />
      </Flex>
      {iframePaymentModel}
    </>
  );
};

export default SubscriptionPaymentForm;
