'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { sv, nb, fi, enGB } from 'date-fns/locale';
import { cn } from '@lib/tw';
import { Button } from 'src/ui/button';
import { Calendar, CalendarProps } from 'src/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'src/ui/popover';
import LanguageContext from '@lib/contexts/languageContext';

const localeMap = {
  en: enGB,
  se: sv,
  no: nb,
  fi,
};

type Props = {
  className?: string;
  calendarProps?: CalendarProps;
  defaultValue?: Date;
  onChange?(date: Date): void;
};

export function DatePicker(props: Props) {
  const { className = '', calendarProps } = props;
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState<Date>(props.defaultValue || null);
  const lang = React.useContext(LanguageContext);

  React.useEffect(() => {
    props.onChange(date);
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-[280px] h-12 border rounded hover:bg-transparent justify-start text-dark text-sm text-left font-normal',
            !date && 'text-muted-foreground',
            className
          )}
          onClick={() => setOpen(true)}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, 'dd / MM / yyyy') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" closeOnFocus={false}>
        <Calendar
          mode="single"
          selected={date}
          initialFocus
          locale={localeMap[lang]}
          // @ts-ignore
          onSelect={(date, ...params) => {
            setDate(date);
            setOpen(false);
            props.onChange(date);
          }}
          {...calendarProps}
        />
      </PopoverContent>
    </Popover>
  );
}
