import React, { useContext } from 'react';
import Styled, { css } from 'styled-components';
import { Trans } from '@lingui/react';
import { useRouter } from 'next/router';
import { t } from '@lingui/macro';
import { Box, Flex } from '@components/layout/Grid';
import { H2 } from '@components/typography/Headings';
import Button from '@components/buttons';
import Icon from '@components/Icon';
import RegisterFormTemplate from '@ecosystems/registerForm.pageTemplate';
import PaymentHandler from '@organisms/modals/payment/PaymentHandler';
import Spinner from '@components/Spinner';
import { getSubscriptionAmountToPay } from '@pages/[lang]/subscription/[step]';
import { getSubscriptionText } from './SubscriptionStep';
import { UserContext } from '@lib/contexts/UserProvider';
import {
  PricingSubscriptionFieldsFragment,
  StoreAdyenDropinPayload,
} from '@gql/generated';
import LanguageContext from '@lib/contexts/languageContext';

const Title = Styled(H2)`
  display: flex;
  align-items: center;
  font-size: 20px;
  color:  ${({ theme }) => theme.colors.neutrals.black};
  margin-bottom: 20px;

  @media(min-width: ${({ theme }) => theme.viewports.laptop}px){
    margin-top: 40px;
  }
`;

const One = Styled.span`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.interactive['color-15']};
  font-weight: bold;
  margin-right: 20px;
`;

const Next = Styled(Button)`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const lockIconStyle = css`
  margin-right: 10px;
`;

const Paragraph = RegisterFormTemplate.Paragraph;

const getCycles = () => {
  return {
    1: t`settings_subscriptions.monthly`,
    3: t`settings_subscriptions.quarterly`,
    12: t`settings_subscriptions.annually`,
  };
};

type Props = {
  code: string;
  selectedSubscription: PricingSubscriptionFieldsFragment;
  onPaymentCompleted(resp: StoreAdyenDropinPayload, arg1: any): void;
  loading: boolean;
};
const RegisterPaymentForm = ({
  code,
  selectedSubscription,
  onPaymentCompleted,
  loading = false,
}: Props) => {
  const router = useRouter();
  const lang = useContext(LanguageContext);
  const [, { loading: userLoading }] = useContext(UserContext);

  const goBackToSubscription = () => {
    const { step: _, lang, ...otherParams } = router.query;

    const query = {
      ...otherParams,
    };
    router.replace({
      pathname: `/${lang}/subscription/select`,
      query,
    });
  };

  if (loading || userLoading || !selectedSubscription) {
    return (
      <Flex
        justifyContent="center"
        data-testid="SubscriptionStep--SubscriptionsLoading"
        my={30}
      >
        <Spinner size={24} spinnerColor="#242424" />
      </Flex>
    );
  }

  const paymentType = ['global', 'extra_global'].includes(
    selectedSubscription?.promotionKind
  )
    ? selectedSubscription?.promotionName
    : selectedSubscription?.subscriptionName;

  const amountToPay = getSubscriptionAmountToPay(selectedSubscription);
  const amountToPayNextCycle = getSubscriptionAmountToPay(
    selectedSubscription,
    true
  );
  const currency = selectedSubscription?.currency;
  const discounted = selectedSubscription.discountedAmount > 0;

  const { description, title } = getSubscriptionText(selectedSubscription);

  const CYCLES = getCycles();

  return (
    <>
      <Title>
        <One>2</One>
        <Trans id="registration.payment.title" />
      </Title>
      <Paragraph size={18} weight={500}>
        <Trans id="registration.payment.ordersummary.label" />
      </Paragraph>
      <Box
        py={15}
        px={20}
        mb={20}
        backgroundColor="#E6E8EF"
        data-testid="registration.payment.selectedPlan"
        data-testselectedplan={paymentType}
        data-testselectedplancurrency={selectedSubscription?.currency}
      >
        <Paragraph size={18} lineHeight={28} weight={500} mt={0} mb={5}>
          {title}
        </Paragraph>
        <Paragraph size={14} lineHeight={22} mt={0} mb={0}>
          {description}
        </Paragraph>
        {code ? (
          <Paragraph size={13} lineHeight={22} mt={0} mb={0}>
            <Trans id="registration.payment.code_applied" />: {code}
          </Paragraph>
        ) : null}
      </Box>
      <Box mb={40}>
        {discounted ? (
          <Flex
            justifyContent="space-between"
            data-testid="registration.payment.discountSection"
          >
            <Paragraph size={18} mb={0}>
              <Trans id="registration.payment.discount.label" />
            </Paragraph>
            <Paragraph
              size={18}
              weight={700}
              mb={0}
              color="#418E1C"
              data-testid="registration.payment.discountAmount"
            >
              {`-${(
                selectedSubscription.amount -
                selectedSubscription.discountedAmount
              ).toFixed(2)} ${currency}`}
            </Paragraph>
          </Flex>
        ) : null}
        <Flex data-testid="registration.payment.total">
          <Paragraph size={18} style={{ flex: 1 }} mr="auto">
            <Trans id="registration.payment.now.label" />
          </Paragraph>
          <Paragraph
            size={18}
            mb={0}
            {...(discounted
              ? {
                  crossed: true,
                }
              : {})}
            data-testid="registration.payment.originalAmount"
          >
            {selectedSubscription.amount} {currency}
          </Paragraph>
          {discounted ? (
            <Paragraph
              size={18}
              weight={700}
              mb={0}
              ml={10}
              data-testid="registration.payment.discountedAmount"
            >
              {amountToPay} {currency}
            </Paragraph>
          ) : null}
        </Flex>
        <Paragraph
          size={15}
          lineHeight={22}
          dangerouslySetInnerHTML={{
            __html: t({
              id: 'registration.payment.nextpayment.body',
              values: {
                cycle: CYCLES[selectedSubscription.months],
                amount: `${amountToPay} ${currency}`,
                recurringAmount: `${amountToPayNextCycle} ${currency}`,
              },
            }),
          }}
        />
      </Box>
      <Flex>
        <Button
          type="submit"
          appearance="outline"
          onClick={goBackToSubscription}
          data-testid="registration.payment.backBtn"
        >
          <Trans id="registration.payment.btn2" />
        </Button>
        <PaymentHandler
          sourcePage="subscriptions"
          code={code}
          paymentType={paymentType}
          amount={amountToPay}
          currency={currency}
          redirectToSuccess={`/${lang}/me/dashboard`}
          redirectToFailed={`/${lang}/subscription/payment?paymentType=${paymentType}`}
          months={selectedSubscription?.months}
          onPaymentComplete={data => {
            onPaymentCompleted(data, { amount: amountToPay, currency });
          }}
          data-testid="registration.payment.paymentModal"
        >
          {open => (
            <Next
              appearance="default-blue"
              onClick={() => open()}
              data-testid="registration.payment.paymentBtn"
            >
              <Icon
                name="ygb-icon-programlock-ic"
                fontSize="16px"
                customStyle={lockIconStyle}
              />
              <Trans id="registration.payment.btn1" />
            </Next>
          )}
        </PaymentHandler>
      </Flex>
    </>
  );
};

export default RegisterPaymentForm;
