import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import {
  AddAdyenPaymentMethodMutation,
  PaymentResultCodeKind,
  RecurringModelInputKind,
  useAddAdyenPaymentMethodMutation,
  useAddTrustlyPaymentMethodMutation,
} from '@gql/generated';
import { UserContext } from '@lib/contexts/UserProvider';

type Props = {
  onPaymentMethodAdded(
    payload: AddAdyenPaymentMethodMutation['addAdyenPaymentMethod']
  ): void;
  redirectTo?: string;
  recurringModel?: RecurringModelInputKind;
};

export const useAddPaymentMethod = (props: Props) => {
  const {
    onPaymentMethodAdded,
    redirectTo,
    recurringModel = RecurringModelInputKind['CardOnFile'],
  } = props;
  const [currentUser] = useContext(UserContext);
  const [paymentError, setPaymentError] = useState(false);
  const [
    addTrustlyMethod,
    { loading: trustlyLoading, error: trustlyError },
  ] = useAddTrustlyPaymentMethodMutation();
  const [
    addAdyenMethod,
    { loading: adyenLoading, error: adyenError },
  ] = useAddAdyenPaymentMethodMutation();

  const handlePaymentSubmit = async variables => {
    setPaymentError(false);

    try {
      if (variables.paymentMethod.providerType === 'trustly') {
        const resp = await addTrustlyMethod({
          variables: {
            redirectTo,
          },
        });
        if (resp.data.addTrustlyPaymentMethod.resultUrl) {
          window.location.href = resp.data.addTrustlyPaymentMethod.resultUrl;
        }
      } else if (variables.paymentMethod.providerType === 'scheme') {
        const resp = await addAdyenMethod({
          variables: {
            ...variables,
            recurringModel,
            redirectToSuccess: redirectTo,
            redirectToFailed: redirectTo,
          },
        });

        // handle exception
        if (!resp?.data) {
          setPaymentError(true);
          return null;
        }

        //
        const resultCode = resp.data?.addAdyenPaymentMethod?.resultCode;

        if (
          resp?.data?.addAdyenPaymentMethod?.errors?.length > 0 &&
          !resultCode
        ) {
          setPaymentError(true);
          Sentry.captureEvent({
            message: 'Failed add payment method',
            contexts: {
              user: {
                id: currentUser?.id,
              },
              response: {
                ...resp?.data?.addAdyenPaymentMethod,
                errors: resp?.data?.addAdyenPaymentMethod?.errors[0],
              },
            },
          });
        } else if (resultCode === PaymentResultCodeKind['Refused']) {
          setPaymentError(true);
        } else if (
          [
            PaymentResultCodeKind['Authorised'],
            PaymentResultCodeKind['Pending'],
          ].includes(resultCode)
        ) {
          onPaymentMethodAdded(resp?.data?.addAdyenPaymentMethod);
        } else if (resultCode === PaymentResultCodeKind['Redirect']) {
          const resultAction = resp.data?.addAdyenPaymentMethod?.resultAction;

          if (String(resultAction?.httpMethod).toLowerCase() === 'post') {
            try {
              const redirectData = JSON.parse(resultAction.redirectData);
              //
              const form = document.createElement('form');
              form.method = 'POST';
              form.action = resultAction?.url;
              //
              const input1 = document.createElement('input');
              input1.name = 'PaReq';
              input1.value = redirectData['pa_req'];
              //
              const input2 = document.createElement('input');
              input2.name = 'MD';
              input2.value = redirectData['md'];
              //
              const input3 = document.createElement('input');
              input3.name = 'TermUrl';
              input3.value = redirectData['term_url'];
              //
              document.body.append(form);
              form.append(input1, input2, input3);
              form.submit();
            } catch (ex) {
              // show an error
            }
          } else {
            window.location.href = resultAction?.url;
          }
        }
      }
    } catch (ex) {
      //
      setPaymentError(true);
      console.error({ ex });
    }
  };

  return {
    addMethod: handlePaymentSubmit,
    loading: trustlyLoading || adyenLoading,
    error: !!(trustlyError || adyenError || paymentError),
  };
};
