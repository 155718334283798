import React, { useEffect } from 'react';
import App from 'next/app';
import Router from 'next/router';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';
import Layout from '@components/layout';
import { initApolloClient } from '@lib/apollo';
import DeviceContext from '@lib/contexts/deviceContext';
import LanguageContext from '@lib/contexts/languageContext';
import GlobalStyle from '@lib/globalStyles';
import muiTheme from '@lib/muiTheme';
import theme from '@lib/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import NProgress from 'nprogress';
import { ThemeProvider } from 'styled-components';
import parser from 'ua-parser-js';
import Snackbar from '@components/Snackbar';
import { AUTH_REFRESH_TOKEN, AUTH_TOKEN, ENABLED_LOCALS } from '@lib/constants';
import redirect from '@lib/redirect';
import UserProvider from '@lib/contexts/UserProvider';
import I18n from '@lib/i18n';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import AnalyticsManager from '@lib/analytics/manager';
import { refreshAuthCookies } from '@lib/auth';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import '@organisms/video-player/css/videojs.scss';
import { CurrentUser, CurrentUserDocument } from '@gql/generated';
import {
  AvailableFlags,
  FLAGS_QUERY,
  buildFlags,
  names,
} from '@lib/hooks/useFlags';
import { CookieBot, GlowCookies } from '@lib/glowCookies';
import {
  GTM_ID,
  GA_TRACKING_ID,
  GA4_TRACKING_ID,
} from '@lib/analytics/ga-manager';
import '@lib/nprogress.css';
import '../src/global.css';
import { RegisterV2CompleteProfileModal } from '@ecosystems/register/RegisterV2CompleteProfileModal';

function isUserAgentSignallingMobile(userAgentString) {
  const ua = parser(userAgentString);
  return ua.device.type === 'mobile';
}

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', path => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', path => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

const AdyenLibrary = dynamic(
  Promise.resolve(() => {
    useEffect(() => {
      if (typeof window['AdyenCheckout'] !== 'function') {
        window['AdyenCheckout'] = require('@adyen/adyen-web');
        window.postMessage({ type: 'ADYEN_LOADED' }, '*');
      }
    }, []);

    return null;
  }),
  { ssr: false }
);

class MyApp extends App<{
  currentUser: CurrentUser;
  _ssr: boolean;
  lang: string;
  flags: AvailableFlags;
  isDeviceMobile: boolean;
  apolloClient: ApolloClient<InMemoryCache>;
}> {
  apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(props) {
    super(props);

    this.apolloClient = initApolloClient({
      lang: props.lang,
      cookies: typeof window === 'undefined' ? props.cookies : null,
      initialState:
        typeof window !== 'undefined'
          ? window['_apollo_cache_']
          : props.apolloCache,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  static async getInitialProps({ Component, ctx }) {
    let flags, currentUser, token, refresh_token;
    const cookies = {};

    const lang = ctx.query.lang;

    let apolloClient;
    try {
      if (ctx.req && Component.RefreshTokens !== false) {
        cookies[AUTH_TOKEN] = ctx.req.cookies?.[AUTH_TOKEN];
        cookies[AUTH_REFRESH_TOKEN] = ctx.req.cookies?.[AUTH_REFRESH_TOKEN];

        try {
          // if new cookies were sent by backend, this function will add them to response
          const refreshData = await refreshAuthCookies(cookies, ctx.res);

          if (refreshData?.cookies) {
            // backend (/graphql) will always return cookies, either fresh or current cookies if they are still valid...
            cookies[AUTH_TOKEN] = refreshData?.cookies[AUTH_TOKEN];
            cookies[AUTH_REFRESH_TOKEN] =
              refreshData?.cookies[AUTH_REFRESH_TOKEN];
          }

          if (refreshData?.currentUser?.id) {
            // for auth check below
            currentUser = refreshData?.currentUser;
          }
        } catch (ex) {
          // we want this code block to continue
          console.error('_app', ex);
        }
      }

      apolloClient = initApolloClient({
        lang,
        cookies,
        initialState:
          typeof window !== 'undefined' ? window['_apollo_cache_'] : {},
      });
      const [flagsResponse, userResponse] = await Promise.all([
        apolloClient.query({
          query: FLAGS_QUERY,
          variables: { names },
        }),
        apolloClient.query({
          query: CurrentUserDocument,
        }),
      ]);

      flags = buildFlags(flagsResponse?.data?.flipperFlags || []);

      if (ctx.req) {
        ctx.req.flags = flags || null;
        ctx.req.currentUser = userResponse?.data?.currentUser;
      }

      currentUser = userResponse?.data?.currentUser;
    } catch (ex) {
      console.error('ex', ex.message);
      // suck error
    } finally {
      if (ctx?.req) {
        // this is needed in getServerSideProps
        ctx.req.apolloClient = apolloClient;
      }
    }

    if (!currentUser && Component.forceAuth) {
      const redirectURL =
        Component?.getNoAuthRedirectURL?.(ctx) ||
        `/${lang}/login?to=${encodeURIComponent(ctx.asPath)}`;
      // If not signed in, send them somewhere more useful
      redirect(ctx, redirectURL);
    }

    const initialProps = {
      _ssr: true,
      lang,
      flags,
      currentUser,
      apolloCache: ctx?.req?.apolloClient?.extract?.(),
      isDeviceMobile: isUserAgentSignallingMobile(
        ctx.req?.headers?.['user-agent']
      ),
      cookies: { token, refresh_token },
      pageProps: {},
    };

    return initialProps;
  }

  async componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    if (this.props.currentUser) {
      const { lang } = this.props.router.query;
      const to = this.props.router?.asPath;
      const query = to ? { to } : {};
      if (
        !(
          this.props.router?.pathname.startsWith('/[lang]/register') ||
          this.props.router?.pathname.startsWith('/[lang]/get-started')
        ) &&
        this.props.currentUser?.trialRequired &&
        this.props.currentUser?.trial &&
        !this.props.currentUser?.trial?.subscriptionPlanType
      ) {
        if (this.props.flags?.enable_manual_trial) {
          this.props.router.replace({
            pathname: `/${lang}/get-started`,
            query,
          });
          return;
        }
      } else if (
        !this.props.currentUser?.completedAt &&
        this.props.currentUser?.trialRequired &&
        !this.props.router?.pathname.startsWith('/[lang]/get-started')
      ) {
        this.props.router.replace({
          pathname: `/${lang}/get-started/complete-profile`,
          query,
        });
      }
    }

    // this is mainly for test envirement
    // to mimic force auth that runs on server
    const { data } = await this.apolloClient.query({
      query: CurrentUserDocument,
    });

    const currentUser = data.currentUser;

    if (this.props.Component['forceAuth'] && !currentUser) {
      const { query, asPath } = this.props.router;
      this.props.router.replace({
        pathname: `/${query.lang}/login`,
        query: { to: encodeURIComponent(asPath) },
      });
    }
  }

  render() {
    const {
      Component: PageComponent,
      lang,
      isDeviceMobile,
      flags,
      ...pageProps
    } = this.props;

    // this is mainly for register page
    // if other pages have same register layout we can exract it
    let PageLayout = Layout;
    if (typeof PageComponent['getPageConfig'] === 'function') {
      if (PageComponent['getPageConfig']()?.layout === 'none') {
        // @ts-ignore
        PageLayout = React.Fragment;
      }
    }

    const language = (lang || pageProps?.router?.query?.lang) as string;
    const locale = ENABLED_LOCALS.includes(language) ? language : 'se';

    return (
      <>
        <Sentry.ErrorBoundary>
          <DeviceContext.Provider value={{ isDeviceMobile }}>
            <Head>
              {/* Use minimum-scale=1 to enable GPU rasterization */}
              <meta
                name="viewport"
                content={
                  'user-scalable=0, initial-scale=1, ' +
                  'minimum-scale=1, width=device-width, height=device-height'
                }
              />
              {flags?.enable_cookiebot && !pageProps.currentUser?.id ? (
                <script
                  id="Cookiebot"
                  src="https://consent.cookiebot.com/uc.js"
                  data-cbid="34846783-7a1c-4038-bb0f-760c7bbed9bd"
                  data-blockingmode="auto"
                  data-culture={lang === 'se' ? 'sv' : 'en'}
                  type="text/javascript"
                ></script>
              ) : null}
              {process.env.site_env === 'test' ? (
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html: `nextjs-portal {
                    display: none;
                  }`,
                  }}
                ></style>
              ) : null}
            </Head>
            <ThemeProvider theme={theme}>
              {/* MuiThemeProvider makes the theme available down the React
              tree thanks to React context. */}
              <MuiThemeProvider theme={muiTheme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {/* i tried to raise this to _document to cover head/meta stuff but didnt work */}
                {/* only thought that comes to mind, is to force running the lingui babel transformer before next/head lifts the meta outside of body */}
                {/* language={lang}
                missing={missing}
                catalogs={{ [lang]: catalog }}
              > */}
                <ApolloProvider client={this.apolloClient}>
                  <UserProvider initialValue={pageProps.currentUser}>
                    <LanguageContext.Provider value={locale}>
                      <I18n>
                        <PageLayout>
                          <GlobalStyle />
                          <PageComponent {...pageProps} />
                          <AdyenLibrary />
                          {flags?.enable_cookiebot ? (
                            <CookieBot />
                          ) : (
                            <GlowCookies locale={locale} />
                          )}
                          {!pageProps?.currentUser?.completedAt &&
                          pageProps?.currentUser?.id &&
                          !pageProps?.currentUser?.trialRequired ? (
                            <RegisterV2CompleteProfileModal />
                          ) : null}
                        </PageLayout>
                        <Snackbar />
                      </I18n>
                    </LanguageContext.Provider>
                  </UserProvider>
                </ApolloProvider>
              </MuiThemeProvider>
            </ThemeProvider>
          </DeviceContext.Provider>
        </Sentry.ErrorBoundary>
        <script
          type="application/javascript"
          dangerouslySetInnerHTML={{
            __html: `
                function initializeClarity(id){
                  (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", '${process.env.CLARITY_ID}');
                }
              `,
          }}
        ></script>
        {!pageProps.currentUser?.impersonated ? (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  ${
                    !pageProps?.currentUser?.id && !flags?.enable_cookiebot
                      ? `gtag('consent', 'default', {
                          ad_storage: 'denied',
                          ad_user_data: 'denied',
                          ad_personalization: 'denied',
                          analytics_storage: 'denied',
                        });`
                      : ''
                  }
                  gtag('js', new Date());
                  gtag('config', '${GTM_ID}');
                  gtag('config', '${GA_TRACKING_ID}');
                  gtag('config', '${GA4_TRACKING_ID}');
              `,
              }}
            />
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              />
            </noscript>
            {/* Google Tag Manager */}
            <script
              src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
            />
          </>
        ) : null}
      </>
    );
  }
}

export default MyApp;

// const empty = () => null;
// // when exported component from App doesn't have getInitialProps
// // next will call getInitialProps on the Component which is a problem in test mode
// // because we return null and we have no providers in the tree which causes issues...
// empty.getInitialProps = () => {
//   return {};
// };

// export default (() => {
//   if (process.env.SITE_ENV === 'test' && typeof window === 'undefined') {
//     return empty;
//   }
//   return ProperApp;
// })();
